<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card
        elevation="0"
        outlined
        color="#f1f1f1"
        :loading="serviceRequestProcessing"
      >
        <template slot="progress">
          <v-progress-linear height="10" indeterminate></v-progress-linear>
          <p class="mt-4 text-center">Processing...</p>
        </template>
        <v-card-title
          v-if="!serviceRequestProcessing"
          class="orange-bg font-weight-bold white--text"
        >
          New Service Request
        </v-card-title>
        <v-card-text v-if="!serviceRequestProcessing" class="mt-3">
          <!-- Start New Service Request section -->
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newRequest.communityName"
                    label="* Community"
                    readonly
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="requestType"
                    :items="requestTypeItems"
                    item-text="name"
                    item-value="id"
                    label="* Choose type..."
                    required
                  ></v-select>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="12" md="12">
                  <v-radio-group
                    v-model="requestType"
                    label="* Choose Type (Only one service type may be selected)"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                        v-for="(requestTypeItem, i) in requestTypeItems"
                        :key="'request_type_col_' + i"
                      >
                        <v-radio
                          :value="requestTypeItem.id"
                          :label="requestTypeItem.name"
                        ></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newRequest.title"
                    label="Service Request Title"
                    required
                  ></v-text-field>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="12" md="6">
                  <v-textarea
                    v-model="newRequest.description"
                    label="* Describe Service Request (Only include a description for one service request per ticket)"
                    required
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <div
                      v-for="attachment in attachmentsObj"
                      cols="12"
                      class="darggable-col"
                      :key="attachment.id"
                    >
                      <MediaUploader
                        class="pb-5"
                        uploadType="photo"
                        :key="attachment.id"
                        :id="`multiFilePost_${attachment.id}`"
                        :index="attachment.id"
                        :accept="accept"
                        :multiple="true"
                        :extensions="extensions"
                        :sizeImage="10485760"
                        :sizeVideo="104857600"
                        :fileId.sync="attachment.fileId"
                        :file-url.sync="attachment.url"
                        :isUploading.sync="isFileUploading"
                        @onMultipleUploaded="onMultipleAddFile"
                        @onChange="onChange"
                        @onDeleteFile="onDeleteFile"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-row>
              <!-- <v-row>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="priority"
                    :items="priorityItems"
                    item-text="name"
                    item-value="id"
                    label="Choose priority..."
                    required
                  ></v-select>
                </v-col>
              </v-row> -->
              <v-row
                v-if="
                  permissions &&
                    permissions.serviceRequest &&
                    permissions.serviceRequest.useOnBehalfOf
                "
              >
                <v-col cols="12" md="6">
                  <v-select
                    :loading="loadingCommunityUsers"
                    v-model="onBehalfOf"
                    :items="communityUsers"
                    item-text="fullName"
                    item-value="id"
                    label="On behalf of user (optional)..."
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row v-if="!isNeighborhoodRequest">
                <v-col cols="12" md="6">
                  <v-textarea
                    v-model="newRequest.location"
                    label="* Please indicate the residence address, address in close proximity or the location in the community (i.e. entrance, pool area, end of Whitehosrse dr., etc.) relating to this service request:"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="assignedTo"
                    :items="ynTeamItems"
                    item-text="fullName"
                    item-value="id"
                    label="* Assigned to"
                    required
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <!-- <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formattedCompletionDate"
                        label="Target Completion Date"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        @blur="
                          completionDate = parseDate(formattedCompletionDate)
                        "
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="completionDate"
                      no-title
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu> -->
                  <v-alert type="warning">
                    Your service request will be scheduled by your Account
                    Manager and a date will be assigned accordingly.
                  </v-alert>

                  <br />
                </v-col>
              </v-row>

              <v-row v-if="isNeighborhoodRequest">
                <v-col cols="12" md="4">
                  <v-radio-group v-model="newRequest.isHoaRequestStr" row>
                    <template v-slot:label>
                      <div>Request Type:</div>
                    </template>
                    <v-radio label="Association Request" value="1"></v-radio>
                    <v-radio
                      label="Individual Home - Extra Service"
                      value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row v-if="isNeighborhoodRequest">
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newRequest.neighborhoodName"
                    label="* Neighborhood Name"
                    :required="isNeighborhoodRequest"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="isNeighborhoodRequest">
                <v-col cols="12" md="6">
                  <v-textarea
                    v-model="newRequest.homeAddress"
                    label="* Home Address"
                    :required="isNeighborhoodRequest"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newRequest.contactName"
                    label="* Contact Name"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="isNeighborhoodRequest">
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newRequest.contactEmail"
                    label="* Contact Email"
                    :required="isNeighborhoodRequest"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newRequest.contactPhone"
                    label="* Contact Phone"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="isNeighborhoodRequest">
                <v-col cols="12" md="4">
                  <v-radio-group v-model="newRequest.isEmailPreferredStr" row>
                    <template v-slot:label>
                      <div>Preferred Contact Method:</div>
                    </template>
                    <v-radio label="Email" value="1"></v-radio>
                    <v-radio label="Phone" value="0"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <p style="font-size: larger; font-weight: bold;">
                    * Indicates required fields
                  </p>
                </v-col>
              </v-row>

              <v-btn color="orange-bg white--text" @click="saveServiceRequest">
                Create New Service Request
              </v-btn>
            </v-container>
          </v-form>

          <!-- End New Service Request section -->
        </v-card-text>
      </v-card>

      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/customerView/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import {
  GET_QUERY,
  POST_DATA,
  API_CUSTOMERS,
  // API_EMPLOYEES,
  API_SERVICE_REQUESTS,
  API_SERVICE_REQUEST_TYPES
  // API_SERVICE_REQUEST_PRIORITIES
} from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
// import { API_USERS } from "@/core/store/crud.module";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
// import { required } from "vuelidate/lib/validators";
import moment from "moment";
import MediaUploader from "@/view/components/imageUploader/mediaUploader.vue";
import Mgr from "@/core/services/security.service";
import permissionsHelper from "@/core/helpers/permissionsHelper";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs,
    MediaUploader
  },
  data: () => ({
    mgr: new Mgr(),
    userInfo: null,
    communityInfo: {},
    newRequest: {
      communityName: "",
      title: "",
      requestType: "",
      description: "",
      isInternal: false,
      attachments: [],
      // priority: "",
      assignedTo: "",
      // targetCompletitioDate: null,
      targetCompletionDate: null,
      isNeighborhoodLoginRequest: false,
      isHoaRequestStr: "0",
      isHoaRequest: false,
      neighborhoodName: "",
      homeAddress: "",
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      isEmailPreferredStr: "0",
      isEmailPreferred: false,
      location: ""
    },
    menu1: false,
    completionDate: null,
    formattedCompletionDate: null,
    requestType: "",
    requestTypeItems: [],

    ynTeamItems: [],

    // priority: "",
    // priorityItems: [
    //   { name: "Low", value: "Low" },
    //   { name: "Normal", value: "Normal" },
    //   { name: "High", value: "High" }
    // ],
    assignedTo: null,
    options: {
      page: 1,
      itemsPerPage: 5
    },
    pageText: "",
    startingAfter: "",
    endingBefore: "",
    previousPage: 1,
    hasMore: false,
    isLoading: false,
    loading: false,
    serviceRequestProcessing: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    attachmentsObj: [{ id: 1, url: null }],
    isFileUploading: false,
    videoExtensions: "mp4,mov,avi,mkv,mpg,vob,wmv,m4v,asf,srt",
    loadingCommunityUsers: false,
    onBehalfOf: null,
    communityUsers: [],
    permissions: {}
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
    // return {
    //   newRequest: {
    //     communityName: {
    //       required
    //     },
    //     title: {
    //       required
    //     },
    //     requestType: {
    //       required
    //     },
    //     description: {
    //       required
    //     },
    //     priority: {
    //       required
    //     },
    //     assignedTo: {
    //       required
    //     },
    //     targetCompletitioDate: {
    //       required
    //     },
    //     neighborhoodName: {
    //       required: this.newRequest.isNeighborhoodLoginRequest
    //     },
    //     homeAddress: {
    //       required: this.newRequest.isNeighborhoodLoginRequest
    //     },
    //     contactName: {
    //       required: this.newRequest.isNeighborhoodLoginRequest
    //     },
    //     contactEmail: {
    //       required: this.newRequest.isNeighborhoodLoginRequest
    //     },
    //     contactPhone: {
    //       required: this.newRequest.isNeighborhoodLoginRequest
    //     }
    //   }
    // };
    //return true;
    // return {
    //   billingInfo: {
    //     subscription: {
    //       nextBillingDates: {
    //         required,
    //         minDate: value =>
    //           dateFormatter.convertToMilliseconds(value) >
    //           dateFormatter.convertToMilliseconds(new Date())
    //       }
    //     }
    //   }
    // };
  },
  watch: {
    completionDate: function() {
      this.formattedCompletionDate = this.formatDate(this.completionDate);
    },
    onBehalfOf: function() {
      if (
        this.communityUsers.filter(el => el.id === this.onBehalfOf).length > 0
      )
        this.newRequest.location = this.communityUsers.filter(
          el => el.id === this.onBehalfOf
        )[0].address;
    }
  },
  async mounted() {
    await this.getEmployees();
  },
  async created() {
    this.userInfo = await this.mgr.getUser(); //.then(userInfo => (this.userInfo = userInfo));
    await this.getComunityInfo();
    let self = this;
    self.loading = true;

    permissionsHelper.getPermissions().then(this.getPermissions);

    await self.getServiceRequestTypes();

    self.newRequest.location = self.userInfo?.profile?.location;
    self.newRequest.contactName = self.userInfo?.profile?.contactName;
    self.newRequest.contactPhone = self.userInfo?.profile?.contactPhone;
    self.newRequest.contactEmail = self.userInfo?.profile?.contactEmail;

    self.loading = false;
  },
  methods: {
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerView",
        params: { customerId: communityId }
      });
    },
    async getComunityInfo() {
      let self = this;

      await self.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: self.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.communityInfo = crud.getPopulatedComunityInfo(response.data);

            if (
              self.userInfo.profile.aspireRole == "Tenant/Resident" ||
              self.userInfo.profile.aspireRole ==
                "Tenant/Resident - Neighborhood"
            ) {
              self.ynTeamItems.push(self.communityInfo.accountManager);
            } else {
              self.ynTeamItems.push(self.communityInfo.propertyManager);
              // self.assignedTo = self.communityInfo.propertyManager.id;
            }
            if (self.userInfo.profile.aspireRole == "Property Manager") {
              self.ynTeamItems.push(self.communityInfo.accountManager);
            }

            self.assignedTo = self.communityInfo.accountManager.id;

            self.createBreadcrumb();
            self.setInternalBreadcrumbs();

            self.newRequest.communityName = self.communityInfo.name;
            self.newRequest.neighborhoodName = self.communityInfo.name;
            // this.getRecipients();
          } else {
            self.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot receive Community details. Something went wrong!",
              color: "red"
            });
          }
        });
    },
    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customerView/" + this.customerId
        },
        {
          title: "Service Requests",
          route: "/customerView/" + this.customerId + "/requests"
        },
        { title: "New Service Request" }
      ];
    },
    // async getServiceRequestPriorities() {
    //   this.loading = true;

    //   await this.$store
    //     .dispatch(GET_QUERY, {
    //       listName: API_SERVICE_REQUEST_PRIORITIES
    //     })
    //     .then(response => {
    //       if (response.status >= 200 || response.status <= 204) {
    //         this.priorityItems = response.data.$values;
    //       } else {
    //         this.$snackbar.showMessage({
    //           content:
    //             response.data ||
    //             "Cannot receive Service Request priorities. Something went wrong!",
    //           color: "red"
    //         });
    //       }
    //       this.loading = false;
    //       this.isLoading = false;
    //     });
    // },
    async getServiceRequestTypes() {
      this.loading = true;
      this.isLoading = true;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_SERVICE_REQUEST_TYPES
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.requestTypeItems = response.data.$values;

            if (this.communityInfo.name.includes("Del Webb Grande Dunes")) {
              let allowedTypes = [
                "General Service",
                "Crew, storm or other damage",
                "Request For Proposal"
              ];
              let allowedTypeIds = [6, 11, 12];
              this.requestTypeItems = this.requestTypeItems.filter(
                el =>
                  allowedTypes.includes(el.name) ||
                  allowedTypeIds.includes(el.id)
              );
            } else if (
              this.communityInfo.name.includes("Del Webb at Lakewood Ranch") ||
              this.communityInfo.name.includes("Del Webb Lakewood Ranch Area")
            ) {
              let disallowedTypes = ["Wildlife Damage", "Mulch Request"];
              let disallowedTypeIds = [14, 15];
              this.requestTypeItems = this.requestTypeItems.filter(
                el =>
                  !disallowedTypes.includes(el.name) &&
                  !disallowedTypeIds.includes(el.id)
              );
            } else if (
              this.communityInfo.name.includes("Sun City Peachtree Homes")
            ) {
              let allowedTypes = [
                "General Service",
                "Irrigation",
                "Mulch Request",
                "Request for Proposal",
                "Plant Request",
                "Sod Request",
                "Crew, storm or other damage"
              ];
              let allowedTypeIds = [4, 6, 11, 12, 14, 16, 17];
              this.requestTypeItems = this.requestTypeItems.filter(
                el =>
                  allowedTypes.includes(el.name) ||
                  allowedTypeIds.includes(el.id)
              );
            }
          } else {
            this.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot receive Service Request types. Something went wrong!",
              color: "red"
            });
          }
          this.loading = false;
          this.isLoading = false;
        });
    },
    saveServiceRequest() {
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   return;
      // }

      this.newRequest.communityId = this.communityInfo.aspireId;
      this.newRequest.isNew = true;
      this.newRequest.isOpen = false;
      this.newRequest.isInProcess = false;
      this.newRequest.isCompleted = false;
      this.newRequest.isInternal = false;
      this.newRequest.skipNotification =
        process.env.VUE_APP_SKIP_NOTIFICATIONS == "1";

      let requestTypeObj = this.requestTypeItems[0];
      if (
        this.requestTypeItems.filter(el => el.id === this.requestType).length >
        0
      )
        requestTypeObj = this.requestTypeItems.filter(
          el => el.id === this.requestType
        )[0];

      this.newRequest.requestType = {
        id: requestTypeObj.id,
        name: requestTypeObj.name
      };

      this.newRequest.title = this.newRequest.requestType.name;

      // let priorityObj = this.priorityItems[0];
      // if (this.priorityItems.filter(el => el.id === this.priority).length > 0)
      //   priorityObj = this.priorityItems.filter(
      //     el => el.id === this.priority
      //   )[0];

      // this.newRequest.priority = {
      //   id: priorityObj.id,
      //   name: priorityObj.name
      // };

      let assignedToObj = this.ynTeamItems[0];
      if (this.ynTeamItems.filter(el => el.id === this.assignedTo).length > 0)
        assignedToObj = this.ynTeamItems.filter(
          el => el.id === this.assignedTo
        )[0];

      this.newRequest.assignedTo = {
        id: assignedToObj.id
      };

      if (this.onBehalfOf) {
        this.newRequest.createdBy = { id: this.onBehalfOf };
      }

      let dueDate = moment.utc(new Date()).add(3, "d");
      let weekDay = dueDate.day();
      let addDays = 0;
      if (weekDay == 6) addDays = 2;
      else if (weekDay == 0) addDays = 1;
      dueDate = dueDate.add(addDays, "d");
      this.newRequest.targetCompletionDate = moment.utc(dueDate);

      this.newRequest.attachments = this.attachmentsObj
        .filter(i => i.url && i.url.length > 0)
        .map(i => {
          return { id: i.fileId, fileUrl: i.url };
        });
      this.newRequest.attachments = this.newRequest.attachments.filter(
        (item, index) => this.newRequest.attachments.indexOf(item) === index
      ); //remove duplicates

      this.newRequest.isNeighborhoodLoginRequest = this.isNeighborhoodRequest;

      if (this.isNeighborhoodRequest) {
        this.newRequest.isEmailPreferred =
          this.newRequest.isEmailPreferredStr == "1";
        this.newRequest.isHoaRequest = this.newRequest.isHoaRequestStr == "1";
      }

      if (
        // !this.newRequest.title ||
        !this.newRequest.communityName ||
        !this.newRequest.description ||
        !this.newRequest.requestType ||
        !this.newRequest.contactName ||
        !this.newRequest.contactPhone ||
        !this.newRequest.targetCompletionDate ||
        (this.newRequest.isNeighborhoodLoginRequest &&
          (!this.newRequest.neighborhoodName ||
            !this.newRequest.homeAddress ||
            !this.newRequest.contactName ||
            !this.newRequest.contactEmail ||
            !this.newRequest.contactPhone))
      ) {
        this.snackbarContent = "Please, populate all fields on the form";
        this.snackbarColor = "red";
        this.$snackbar.showMessage({
          content: this.snackbarContent,
          color: this.snackbarColor
        });
        return;
      }

      this.serviceRequestProcessing = true;
      let self = this;
      self.$store
        .dispatch(POST_DATA, {
          listName: `${API_SERVICE_REQUESTS}`,
          data: this.newRequest
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            self.snackbarContent =
              "Service Request has been created successfully";
            self.snackbarColor = "green";

            self.$router.push({
              name: "CustomerViewRequests",
              params: { customerId: self.customerId }
            });
          } else {
            self.snackbarContent = response.data;
            self.snackbarColor = "red";
          }
          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });

          self.serviceRequestProcessing = false;
        });

      // this.saveCustomer();
    },

    async getEmployees() {
      this.loadingCommunityUsers = true;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: `${API_CUSTOMERS}/${this.customerId}/users`
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityUsers = response.data.$values;
          } else {
            this.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot receive a list of users. Something went wrong.",
              color: "red"
            });
          }
          this.loadingCommunityUsers = false;
        });
    },

    // async getRecipients() {
    //   // let comunityItems = dataModels.communityDetails;
    //   await this.$store
    //     .dispatch(GET_QUERY, {
    //       listName: API_EMPLOYEES
    //     })
    //     .then(response => {
    //       if (response.status >= 200 || response.status <= 204) {
    //         this.ynTeamItems = response.data;
    //       } else {
    //         this.$snackbar.showMessage({
    //           content:
    //             response.data ||
    //             "Cannot receive Service Request recipients. Something went wrong!",
    //           color: "red"
    //         });
    //       }
    //     });
    // },
    setStartingAfter() {
      if (this.userInvoices && this.userInvoices.length > 0) {
        this.startingAfter = this.userInvoices[this.userInvoices.length - 1].id;
        this.endingBefore = "";
      }
    },
    setEndingBefore() {
      if (this.userInvoices && this.userInvoices.length > 0) {
        this.endingBefore = this.userInvoices[0].id;
        this.startingAfter = "";
      }
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.communityInfo.name,
          route: "/customerView/" + this.customerId
        },
        {
          title: "Service Requests",
          route: "/customerView/" + this.customerId + "/requests"
        },
        { title: "New Service Request" }
      ]);
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    onMultipleAddFile(files) {
      for (var i = 1; i < files.length; i++) {
        let max = Math.max(...this.attachmentsObj.map(i => i.id));
        this.attachmentsObj.push({
          id: (max += 1),
          url: files[i].fileUrl,
          fileId: files[i].id
        });
      }
      let max = Math.max(...this.attachmentsObj.map(i => i.id));
      this.attachmentsObj.push({ id: (max += 1), url: null });
      this.$nextTick(() => files.forEach(f => this.onChange(f)));
    },
    onChange(file) {
      if (file.fileUrl) {
        var found = this.attachmentsObj.find(f => f.url == file.fileUrl);
        found.fileId = file.id;
      }
    },
    onDeleteFile(fileData) {
      let index = this.attachmentsObj.findIndex(f => f.id === fileData.index);
      this.attachmentsObj.splice(index, 1);
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      // return moment(date).format("MM/DD/YYYY");
      return dateFormatter.formatDateUs(date);
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  computed: {
    accept() {
      return "*/*";
    },
    extensions() {
      return "gif,jpg,jpeg,png,webp,mp4,mov,avi,mkv,mpg,vob,wmv,m4v,asf,srt,pdf,doc,docx";
    },
    isNeighborhoodRequest() {
      return (
        this.userInfo?.profile.aspireRole == "Tenant/Resident - Neighborhood"
      );
    },
    isResident() {
      return (
        this.userInfo.profile.aspireRole == "Tenant/Resident - Neighborhood" ||
        this.userInfo.profile.aspireRole == "Tenant/Resident"
      );
    }
  }
};
</script>
